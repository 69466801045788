@import 'src/assets/styles/index';

$footerHeight: 58px;

$base00: #202746;
$base01: #293256;
$base02: #5e6687;
$base03: #6b7394;
$base04: #898ea4;
$base05: #979db4;
$base06: #dfe2f1;
$base07: #f5f7ff;
$base08: #c94922;
$base09: #c76b29;
$base0A: #c08b30;
$base0B: #ac9739;
$base0C: #22a2c9;
$base0D: #3d8fd1;
$base0E: #6679cc;
$base0F: #9c637a;

.workout {
  padding-bottom: $footerHeight;
  &__header {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__routine {
    opacity: 0.7;
    font-size: 1.125rem;
    transition: opacity 0.25 ease-in-out;
    &::placeholder {
      color: $text;
    }
    &:focus {
      opacity: 1;
    }
  }
  &__body {
    position: relative;
    margin: -5px 0;
  }
  &__display {
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: none;
    white-space: pre-wrap;
    height: 100%;
    padding: 5px;
    .letters{
      color: $base00;
    }
    .numerical{
      color: $base01;
    }
    .postnumerical{
      color: $base02;
    }
    .idle{
      color: $base03;
    }
    .special{
      color: $base04;
    }
    .finished{
      color: $base05;
    }
    .comment{
      color: $base06;
    }
    .unknown{
      color: $base08;
    }
    .Rest {
      // color: $base08;
      text-decoration: underline;
    }
    .Reps {
      color: $base09;
    }
    .Sets {
      color: $base0F;
    }
    .Any {
      color: $base08;
    }
    .Time {
      color: $base0B;
    }
    .Weight {
      color: $base0C;
    }
    .Distance {
      color: $base0D;
    }
    .exercise-name {
      color: $base0E;
    }
    .tag {
      color: $highlight;
    }
  }
  &__text {
    width: 100%;
    height: auto;
    display: block;
    resize: none;
    padding: 0;
    color: transparent;
    caret-color: $text;
    overflow: hidden;
    padding: 5px;
    // todo: text that wraps multiple lines is an issue atm for scrolling and the num rows in the textarea
    &::placeholder {
      opacity: 0.5;
    }
  }
  &__footer {
    height: $footerHeight;
    text-align: center;
    @include defaultShadow;    
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    font-size: 0.875rem;
    background-color: $background;
  }
  &__timebtn {
    appearance: none;
    border: none;
    appearance: inline;
    box-shadow: none;
    outline: none;
    color: $text;
    background-color: $background;
    font-size: inherit;
    font-family: 'Roboto', 'Helvetica', sans-serif;
    cursor: pointer;
  }


  &__actions {
    width: 100vw;
    position: fixed;
    padding: 12px 12px 70px;
    top: 100%;
    background-color: $background;
    @include defaultShadow;
    left: 0;
    transition: 0.3s ease-in-out;
    &--open {
      transform: translateY(-100%);
    }
  }
  &__actionbtn {
    position: absolute;
    right: 5px;
    top: 50%;
    outline: none;
    appearance: none;
    border: none;
    box-shadow: none;
    transform:   translateY(-50%) rotateZ(90deg);
    font-size: 1rem;
    cursor: pointer;
    color: $text;
    transition: 0.25s;
    background-color: transparent;
    line-height: 12px;
    padding-bottom: 12px;
    &--open {
      color: $highlight;

    }
  }
}

.dangerButton {
  border: 2px solid red;
  appearance: none;
  border-radius: 3px;
  text-align: center;
  padding: 8px 10px;
  background-color: red;
  color: $background;
  cursor: pointer;
}

.exerciserow {
  &--super {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      right: calc(100% + 3px) ;
      width: 2px;
      height: 100%;
      display: block;
      background-color: $base0F;
    }
  }
}