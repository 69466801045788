@import 'src/assets/styles/index';

$burgerscale: 30px;
$padding: 12px;

.app {
  
  padding-top: $burgerscale + 2*$padding;
}

.nav {
  &__bar {
    position: fixed;
    top: 0;
    height: $burgerscale;
    left: 0;
    background: white;
    width: calc(100% - #{2*$padding});
    display: flex;
    align-items: center;
    padding: $padding;
    box-sizing: content-box;
    @include defaultShadow;    
    z-index: 4;
  }
  &__button {
    appearance: none;
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
    position: relative;
    display: inline-block;
    height: $burgerscale;
    width: $burgerscale;
    &:focus {
      outline: none;
    }
  }
  &__logo {
    max-height: 100%;
    margin-left: 30px;
    position: relative;
    top: 1px;
  }
  &__sidebar {
    position: fixed;
    top: 2*$padding + $burgerscale;
    padding: $padding;
    width: 100%;
    background:  white;
    z-index: 3;
    display: block;
    transition: 0.25s ease;
    left: 0;
    $height:((2 * $padding) + $burgerscale);
    height: calc(100vh - #{$height} );
    transform: translateX(-100%) translateX(-10px);
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
    &--open {
      transform: translateX(0);
    }
  }
  &__link {
    display: block;
    margin: auto;
    text-align: left;
    color: $text;
    text-decoration: none;
    margin: 20px;
  }
}

.burger {
  position: relative;
  display: inline-block;
  height: $burgerscale;
  width: $burgerscale;
  span {
    position: absolute;
    width: 0.8*$burgerscale;
    height: 0.1*$burgerscale;
    background: $text;
    left: 50%;
    top: 50%;
    transition: 0.25s ease;
    transform-origin: left;
    &:first-child {
      transform: translate(-50%, -50%) translateY(-#{0.25 * $burgerscale});
    }
    &:nth-child(2) {
      transform: translate(-50%, -50%) translateY(0px);
    }
    &:nth-child(3) {
      transform: translate(-50%, -50%) translateY(#{0.25 * $burgerscale});
    }
  }
  &--back {
    span {
      &:first-child {
        transform: translate(-54%, -25%)  scaleX(0.5) rotateZ(-25deg) skewX(30deg);
      }
      &:nth-child(2) {
        transform: translate(-50%, -50%) translateY(0px) scaleX(0.8);
      }
      &:nth-child(3) {
        transform: translate(-54%, -75%) scaleX(0.5) rotateZ(25deg) skewX(-30deg);
      }
    }
  }
}