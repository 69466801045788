@import 'src/assets/styles/index';

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', sans-serif;
  background-color: $background;
  background: $background;
  color: $text;
  font-size: 20px;
}

textarea, input, select {
  background-color: $background;
  color: $text;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', sans-serif;
  border: none;
  margin: none;
  padding: none;
  appearance: none;
}
button {
  font-family: 'Roboto', 'Helvetica', sans-serif;
}

* {
  box-sizing: border-box;
}
.app {
  background: $background;
  min-height: 100vh;

  // filter: invert(1); // shit dark mode?
}
.visually-hidden {
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}