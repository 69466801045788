@import 'src/assets/styles/index';

.exercise {
  border-top: 2px solid $highlight;
  padding: 15px 0px 15px;
  position: relative;
  // border-radius: 20px;
  margin: 5px -8px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  &__title {
    position: absolute;
    top: 0px;
    margin: 0;
    font-size: 1rem;
    background-color: $background;
    transform: translateY(-50%);
    padding: 0 8px;
    margin-left: 0px;
  }
}

.set {
  border: 2px solid $text;
  padding: 5px 10px 8px;
  position: relative;
  border-radius: 20px;
  margin: 15px 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  &__title {
    position: absolute;
    top: 0px;
    left: 50%;
    margin: 0;
    font-size: 1rem;
    background-color: $background;
    transform: translate(-50%, -50%);
    padding: 0px 8px;
    white-space: nowrap;
    font-weight: bold;
  }
  &__tofailure {
    position: absolute;
    bottom: 0px;
    left: 50%;
    margin: 0;
    font-size: 1rem;
    background-color: $background;
    transform: translate(-50%, 50%);
    white-space: nowrap;
    padding: 0px 8px;
    font-size: 0.8rem;
    font-weight: bold;
  }
}

.quantity {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 8px;
  margin: 8px;
  &__icon {
    height: 24px;
    display: inline-block;
  }
  &__amount {
    font-family: 'Open Sans', sans-serif;
    font-size: 2rem;
    margin: 10px auto 8px;
    line-height: 1;
  }
  &__units {
    margin: 0px auto;
    font-size: 1.1rem;
    line-height: 1;
    height: 19px;
    font-weight: bold;
  }
}