@import 'src/assets/styles/index';

.micro-workout {
  &__item { 
    padding: 12px 5px;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    align-items: center;
  }
  &__title {
    flex: 1;
    color: $text;
  }
  &__date {
    justify-self: flex-end;
    font-size: 0.875rem;
    color: $text;
    opacity: 0.5;
  }
  &__icon {
    margin-right: 22px;
  }
}