@import 'src/assets/styles/index';

.page {
  padding-left: 16px;
  padding-right: 16px;
  &__title {
    font-size: 1.125rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}


$footerHeight: 58px;
$footerButtonRadius: 34px;
$footerButtonInnerRadius: 30px;
$footerButtonPosition: 75%;

.workouts {
  padding-bottom: $footerHeight + 10px;
}

.workouts-footer {
  filter: drop-shadow(0px 2px 3px rgba(0,0,0,0.3));
  width: 100%;
  position: relative;
  height: $footerHeight;
  padding: 12px;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: calc( #{$footerButtonPosition} - #{$footerButtonRadius});
    background: $background;
    left: 0;
    top: 0;
    z-index: -1;
    box-shadow: 2*$footerButtonRadius $footerButtonRadius 0 0 $background;
  }
  &:before {
    content: "";
    position: absolute;
    left: $footerButtonPosition;
    top: 0;
    transform: translate(-50%, -50%) rotateZ(45deg);
    border-radius: 50%;
    border: 500px transparent solid;
    border-bottom: 500px $background solid;
    border-right: 500px $background solid;
    box-sizing: content-box;
    width: 2*$footerButtonRadius;
    height: 2*$footerButtonRadius;
    display: block;
    z-index: -1;
    pointer-events: none;
  }
  &__wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  &__navlink {
    margin-right: 10%;
    text-decoration: none;
    color: $text;
    font-size: 0.75rem;
    svg {
      display: block;
      margin: auto;
      transition: color 0.25s ease;
    }
    &--active {
      svg {
        color: $highlight;
      }
    }
  }
}


.create-tray {
  padding: 12px 12px 56px;
  width: 100%;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
  top: 100%;
  transition: 0.25s ease;
  position: absolute;
  left: 0;
  background-color: $background;
  &__title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.125rem;
  }
  &__link {
    display: flex;
    padding-left: 20px;
    text-decoration: none;
    margin: 20px 0;
    color: $text;
    align-items: center;
  }
  &__body {
    position: relative;
    z-index: 1;
  }
  &__icon {
    margin-right: 22px;
  }
  &__toggle {
    transition: 0.25s ease;
    transform: translate(-50%, - ( $footerHeight + $footerButtonInnerRadius ));
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
    top: 0;
    left: $footerButtonPosition;
    background: white;
    border: none;
    border-radius: 50%;
    display: block;
    position: absolute;
    height: 2*$footerButtonInnerRadius;
    width:2*$footerButtonInnerRadius;
    padding: none;
    z-index: 5;
    &:focus {
      outline: none;
    }
    &:before,&:after {
      content: "";
      position: absolute;
      display: block;
      height: 0.12*$footerButtonInnerRadius;
      width: 1.14*$footerButtonInnerRadius;
      left: 50%;
      top: 50%;
      background-color: $highlight;
    }
    &:before {
      transform: translate(-50%, -50%) rotateZ(90deg);
    }
    &:after {
      transform: translate(-50%, -50%);
    }
  }
  &--open {
    transform: translateY(-100%);
    .create-tray {
      &__toggle {
        box-shadow: 0.85px 0.85px 3px 0px rgba(0,0,0,0.3);
        transform: translate(-50%, -50%) rotateZ(45deg);

      }
    }
  }
}