@import 'src/assets/styles/index';

.routineicon {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
  position: relative;
  &__img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  &__editor {
    position: absolute;
    top: calc(100% + 14px);
    right: 0;
    background-color: $background;
    @include filterShadow;
    width: calc( 100vw - 32px );
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    display: flex;
    opacity: 0;
    pointer-events: none;
    transform: translateY(3px);
    transition: 0.1s ease-in-out;
    will-change: transform, opacity;
    z-index: 1;
    &--show {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
      transition: 0.25s ease-in-out;
    }
    &:before {
      content: "";
      right: 8px;
      bottom: 100%;
      display: block;
      border: 10px solid $background;
      border-bottom: 10px solid transparent;
      border-right: 10px solid transparent;
      transform: translateY(52%) rotateZ(45deg) ;
      position: absolute;
      transform-origin: center;
    }
    &::after {
      content: "";
      flex: auto;
    }
  }
  &__editorimg {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 5px 10px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  &__addicon {
    appearance: none;
    border: none;
    background-color: $background;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    &:focus{ 
      outline: none;
    }
    &:before,&:after {
      content: "";
      position: absolute;
      display: block;
      height: 2px;
      width: 18px;
      left: 50%;
      top: 50%;
      background-color: #ACB1BA;
    }
    &:before {
      transform: translate(-50%, -50%) rotateZ(90deg);
    }
    &:after {
      transform: translate(-50%, -50%);
    }
  }
  &--active {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: $highlight;
      @include defaultShadow;
    }
  }
  &--blank {
    background: #ECECEC;
    border: 2px solid #ACB1BA;
  }
}